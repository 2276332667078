

<template>
  <div
   v-editable="editing && blok"
    class="menu-item"
    :class="{
      active: isActive,
      'is-open':is_open,
      'has-sub-menu': blok.submenu && blok.submenu.length > 0,
    }"
  >
  <!-- {{ blok.link }} -->
    <NuxtLink class=""  :to="blok.link?.story && localePath(blok.link.story.full_slug) || ''">{{
    _label
    }}</NuxtLink>
    <span class="menu-item__toggle-icon" v-if="blok.submenu?.length > 0" @click="toggleSubmenu()"></span>

    <div class="sub-menu" v-if="blok.submenu && blok.submenu.length > 0">
      <ul class="sub-menu__wrap">
        <li
          class="sub-menu__item font-normal"
          v-for="subitem in blok.submenu"
          :key="subitem._uid"
        >
          <MenuLink :blok="subitem"></MenuLink>
        </li>
      </ul>
    </div>
  </div>
</template>
<script setup >
const { getUrl } = useConfig();
const { editing } = useSettings();
const route = useRoute();
const props = defineProps({ blok: Object });
const is_open = ref(false)
const { locale, locales } = useI18n()
// console.log( props.blok.link?.story)
// let _label =  ( locale.value != 'en' && props.blok.link?.story?.translated_slugs?.find(ts => ts.lang == locale.value)?.name) || props.blok.label || props.blok.link?.story.name || "label missing"
// console.log(props.blok)
function toggleSubmenu() {
  // console.log("toggle",is_open.value)
  is_open.value = !is_open.value 

}
onBeforeUpdate((val) => {
  // console.log(locale.value)
  // console.log(( locale.value != 'en' && props.blok.link?.story?.translated_slugs?.find(ts => ts.lang == locale.value)?.name) || props.blok.label || props.blok.link?.story.name || "label missing")
   
  // console.log(props.blok.label || props.blok.label || props.blok.link?.real_story.translated_slugs?.find(ts => ts.lang == locale)?.name || props.blok.link?.real_story.name )
})
let _label = computed(() => {
  var translabel = props.blok.link?.story?.translated_slugs?.find(ts => ts.lang == locale.value)?.name;
  // if (props.blok.link?.story?.translated_slugs) {
  //   console.log(props.blok.label, props.blok.link?.story,props.blok.link?.story?.translated_slugs)
  // }
  // props.blok.link?.story?.translated_slugs?.find(ts => {
  //   console.log(ts.lang ,"==", locale.value)
  // })
  // console.log(locale.value,"-",props.blok.label)
  // console.log(props.blok.label)
  // return translabel || props.blok.link?.story?.name || props.blok.label  
  return translabel || props.blok.link?.story?.name   
})
let isActive = computed(() => {
  // console.log(route.fullPath,props.blok.link.story.slug)
  // console.log(route.params.slug[route.params.slug.length-1] , (props.blok.link.story.slug))
  var _slug = route.params.slug;
  // console.log(_slug)
  return _slug
    ? props.blok.link?.story && route.params.slug[route.params.slug.length - 1] ==
        props.blok.link.story.slug
    : false;
});
</script>
<style lang="scss">
// @use "@/assets/styles/scss/mixins/all.scss" as *;
.menu-item {
  .layout--default & {
    margin-right: 2rem;
    position: relative;
    white-space: nowrap;
    &.l0 > .sub-menu {
      // padding-left:1rem;
      display: none;

      // border:1px solid $c-green-40;

      position: absolute;
      // border-radius: 1rem;
      // left:-1rem;
      // top:2rem;

      padding-top: 2rem;
      .menu-item:not(.has-sub-menu) {
         font-size: 18px;
         @include breakpoint(lg){
                margin-bottom: 0.5rem;

              }
        }
      > .sub-menu__wrap {
         a{
           &.router-link-exact-active{
            &::before{
              left:-12px;
              top:calc(50% - 3px)
            }
           }
        }
        @include breakpoint(lg){

          background-color: #fff;
            border-radius: 5px;
        padding: 1rem 3rem 1.5rem 1.75rem;
        margin-left: -1.5rem;
        }
      
        > .sub-menu__item {
          margin-top: .5rem;
       

           &:not(:first-child){
                > .menu-item.has-sub-menu > a {
 display: block;
                margin-top:1.5rem;
                }
              }
          > .menu-item {
            margin-right: 0px;
          
            &.has-sub-menu {
             
              > a {
                // font-family: $font-serif;
                // font-size: 1.5rem;
               
                &::after {
                  content: "";
                  height: 1px;
                  width: 100%;
                  display: block;
                  background-color: currentColor;
                  margin: 0.5rem 0rem 1rem;
                }
                &.router-link-exact-active{
                  &::before{
                    
                    top:calc(.5em - 1px)
                  }
                }
              }
              .sub-menu{
                .menu-item{
                  // background-color: hotpink;
                  &:not(:last-child){
                    margin-bottom: 1rem;

                  }

                }
              }
            }
          }
        }
      }
      .sub-menu {
        top: 0px;
        padding: 0rem;
        //  left:1rem;
        border: none;
        position: relative;
        display: block;
       
      
      }
      &__item {
        // >
      }
    }
    > a[href] {
      &:hover {
        // font-weight: bolder;
      }
      &.router-link-exact-active{
        position: relative;
        font-weight: bold;
        &::before{
          content:"";
          position: absolute;
          width:7px;
          height:7px;
          bottom:-10px;
          left:calc(50% - 3.5px);
          border-radius: 50%;
          background-color:currentColor ;
          display: inline-block;
        }
      }
    }
    &.open,
    &:hover {
      // >a::after{
      //   bottom:-3px;
      //   opacity: 1;
      // }
      > .sub-menu {
        display: block;
      }
    }
  }
  .layout--variation & {
    &.active {
      > a {
        font-weight: bold;
      }
    }
    > a {
      position: relative;
      &::after {
        content: "";
        pointer-events: none;
        position: absolute;
        width: 100%;
        height: 2px;
        background-color: currentColor;
        bottom: -7px;
        left: 0px;
        opacity: 0;
        transition: all 0.15s cubic-bezier(0.895, 0.03, 0.685, 0.22);
      }
      &:hover {
        &::after {
          bottom: -2px;
          opacity: 1;
        }
      }
    }
    .sub-menu {
      padding-left: 1rem;
      display: none;
      &__item {
      }
    }
    &.open,
    &:hover {
      // >a::after{
      //   bottom:-3px;
      //   opacity: 1;
      // }
      > .sub-menu {
        display: block;
      }
    }
  }
}
</style>
